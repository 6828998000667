/*.sf-header {
    position: fixed;
    top: 0;
    z-index: 998;
    width: 100%;
    background: transparent;
}

.sf-glass{
    padding-inline: 20px;
     backdrop-filter: blur(20px);
     border-radius: 10px;
     margin: 0;
     box-shadow: rgb(0 0 0 / 40%) 0px 48px 100px 0px;
}*/
.top-mean-nav{
margin-top: 36px;
}
.sf-header{
    background: transparent;
    position: absolute;
    z-index: 999;
    width: 100%;
}

.profile-img{
    text-align: center;
    background: #242424;
    border-radius: 50%;
    color: white;
    border: 1px solid #007aff;
}

@media screen and (max-width: 576px) {

    .search-wrap-2.search-open {
    opacity: 1;
    right: 100%;
    visibility: visible;
    z-index: 99;
    top: 65px;
    width: 100%;
}
}
@media screen and (min-width: 481px) and (max-width: 576px){
    .search-wrap-2.search-open {
        opacity: 1;
        right: 0;
        visibility: visible;
        z-index: 99;
        top: 63px;
        margin-right: 0;
        padding-bottom: 0;
        width: 315px;
        left: -248px;
    }
}
@media screen and (max-width: 480px) {

    .search-wrap-2.search-open {
        opacity: 1;
        right: 100%;
        visibility: visible;
        z-index: 99;
        top: 65px;
        width: 315px;
        left: -78px;
    }
}