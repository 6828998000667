.card-table{
    --flow-space: 0.5em;
    --hsl: var(--hue), var(--saturation), var(--lightness);
    flex: 1 1 14rem;
    padding: 1.5em 2em;
    display: grid;
    grid-template-rows: auto auto auto 1fr;
    align-items: start;
    gap: 1.25em;
    color: #eceff1;
    background-color: #2b2b2b;
    border: 1px solid #eceff133;
    border-radius: 15px;
}
h3 {
    font-size: 20px;
    font-weight: 600;
}

.card_inner1 h2.card__heading {
    font-size: 20px;
    font-weight: 600;
    color: #237aff;
}

.cards__card.card img {
    width: 50px;
    height: auto;
}
.cards__inner1 {
    width: 400px;
}

@media screen and (max-width: 480px){
    .cards__inner1 {
        width: 100%;
    }
}