.btn.theme-color-four {
    background: #007aff;
    border: none;
    color: #fff;
    border-radius: 6px;
    display: inline-block;
    line-height: 1;
    padding: 10px 18px;
    font-weight: 500;
    background-color: #007aff;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}