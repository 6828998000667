.dashboard-list .tablist-btn {
  padding-bottom: 0;
  font-size: 16px;
  border-bottom: none;
  margin-right: 0;
  font-weight: 500 !important;
}


ul.nav.dashboard-list.white.mb--60 {
  width: 373px;
  background-color: #1e272d;
  border-radius: 10px;
  padding: 0;
  align-items: center;
}

a.tablist-btn.fw-bold {
  padding: 9px;
}

.dashboard-list.white .tablist-btn.active {
  border-bottom: none;
  background-color: #237aff;
  border-radius: 5px;
  padding: 9px;
}


.container-fluid {
    padding: 0 100px;
  }
  .sf-header-padding {
    padding-top: 170px !important;
}
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
 .container-fluid {
      padding: 0 50px;
    }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
.container-fluid {
      padding: 0 30px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
 .container-fluid {
      padding: 0 40px;
    }
  }
  
  @media only screen and (max-width: 767px) {
.container-fluid {
      padding: 0 15px;
    }
  }
@media screen and (max-width: 390px){
  ul.nav.dashboard-list.white.mb--60 {
    width: 100%;
    background-color: #1e272d;
    border-radius: 10px;
    padding: 0;
    align-items: center;
}
ul.nav.dashboard-list.white.mb--60 li {
  width: 100%;
}
.dashboard-list.white .tablist-btn.active {
  border-bottom: none;
  background-color: #237aff;
  border-radius: 5px;
  padding: 9px;
  width: 100%;
}
.dashboard-list.white .tablist-btn {
  color: #ffffff;
  width: 100%;
}
}